<template>
  <div>
    <a-card title="待办事项" :bordered="false">
      <a-row gutter="24" style="text-align: center" type="flex" justify="space-around">
           <!-- <a-col :span="4" @click="$router.push('/order/pick_up')">
          <div
            class="todo-card"
            style="background-color: #ffdee4; color: #fc6181"
          >
            {{pickup_count}}
          </div>
          <div>待提货订单</div>
        </a-col> -->
        <a-col :span="4" @click="$router.push('/order/pending')">
          <div
            class="todo-card"
            style="background-color: #dfecff; color: #5a8bff"
          >
            {{deliver_count}}
          </div>
          <div>待发快递订单</div>
        </a-col>
     
        <!-- <a-col :span="4">
          <div
            class="todo-card"
            style="background-color: #fff0c4; color: #fdb64a"
          >
            待发物流订单
          </div>
          <div>待发物流订单</div>
        </a-col> -->
        <a-col :span="4" @click="$router.push('/order/return')">
          <div
            class="todo-card"
            style="background-color: #dfecff; color: #5a8bff"
          >
            {{refund_count}}
          </div>
          <div>待处理退款退货</div>
        </a-col>
        <a-col :span="4" @click="$router.push({path: '/goods/evaluate', query: {current: '2'}})">
          <div
            class="todo-card"
            style="background-color: #ffdee4; color: #fc6181"
          >
           {{audit_evaluation_count}}
          </div>
          <div>商品评价待审核</div>
        </a-col>
        <a-col :span="4" @click="$router.push('/member/activateCard')">
          <div
            class="todo-card"
            style="background-color: #fff0c4; color: #fdb64a"
          >
            {{audit_card_count}}
          </div>
          <div>会员卡申请待审核</div>
        </a-col>
      </a-row>
    </a-card>
    <commodityStatistics style="margin-top:20px;"></commodityStatistics>
  </div>
</template>

<script>
export default {
  name: "ShopOverview",
  components:{
    commodityStatistics:()=>import("@/views/shopOverview/commodityStatistics"),
  },
  data() {
    return {
      audit_card_count: 0,
      audit_evaluation_count: 0,
      deliver_count: 0,
      pickup_count: 0,
      refund_count: 0,
    };
  },
  created() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.$axios.get("/todo/").then((res) => {
        console.log(res);
        this.audit_card_count = res.audit_card_count;
        this.audit_evaluation_count = res.audit_evaluation_count;
        this.deliver_count = res.deliver_count;
        this.pickup_count = res.pickup_count;
        this.refund_count = res.refund_count;
      });
    },
  },
};
</script>

<style scoped>
.todo-card {
  line-height: 56px;
  border-radius: 6px;
  font-size: 30px;
  cursor: pointer;
  margin-bottom: 8px;
}
</style>